#ReactGridGallery {
  overflow: hidden;
}

.ReactGridGallery_tile-viewport {
  border-radius: 2px;

  &:hover {
    filter: opacity(0.9) brightness(1.3);
  }
}

.image_1swebtw-o_O-imageLoaded_zgbg08 {
  border-radius: 7px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.footer_60scne {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
