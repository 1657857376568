html {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  background-image: url("images/background.jpg");
  background-position-x: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.siteContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
